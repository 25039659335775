import React, { Fragment, useState } from 'react'
import Logo from '../images/new/logo-kingdom-white.svg'
import LogoMobile from '../images/new/logo-kingdom.svg'
import { Link } from 'gatsby'
import ResponsiveMenu from '../components/ResponsiveNavbar'
import Open from '../images/open.svg'
import Close from '../images/close-y.svg'
import Modal from '../components/RodalWhite'
import Facebook from '../images/new/icons/menu/facebook.svg'
import Twitter from '../images/new/icons/menu/twitter.svg'
import Instagram from '../images/new/icons/menu/instagram.svg'
import Youtube from '../images/new/icons/menu/youtube.svg'
import Linkedin from '../images/new/icons/menu/linkedin.svg'
import LogoWhite from '../images/new/logo-kingdom-white.svg'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import {
  navBar2,
  container,
  logo,
  navBarMenu,
  navMenu,
  mobileMenu,
  togle,
  linkTo,
  mobile,
  desktop,
  headerMobile
  // btnMobile
} from '../components/styles/NavBar.style'

import {
	linksAcordionMobile,
  socialIcons
} from '../components/styles/Rodal.styles'


const NavBar = props => {
  const [visible, setVisible] = useState(false)
  return (
    <div css={navBar2}>
      <div css={container}>
        <div css={logo} title='KINGDOM School'>
          <Link
            to='/'
            offsetTop={100}
            onFocus={() => setVisible(false)}
            css={desktop}
          >
            <img src={Logo} alt='Logo' style={{ width: '100%' }} />
          </Link>
          <Link
            to='/'
            offsetTop={100}
            onFocus={() => setVisible(false)}
            css={mobile}
          >
            <img src={LogoMobile} alt='Logo' style={{ maxWidth: '80%' }} />
          </Link>
        </div>
        <div css={navMenu}
          style={{
            display: props.isMenuDisabled ? 'none' : 'flex'
          }}>
          <ResponsiveMenu
            menuOpenButton={<img src={Open} alt='/' />}
            menuCloseButton={<img src={Close} alt='/' />}
            changeMenuOn='960px'
            largeMenuClassName={navBarMenu}
            smallMenuClassName={mobileMenu}
            menuVisible={visible}
            setMenu={setVisible}
            menu={
              <div css={togle}>
                {props.location && !props.location.pathname.match(/^\/(#.*|\?.*)*$/)?
                  (
                    <Fragment>
                      <Link
                        to='/#sobre-a-sapientia'
                      >
                        HOME
                      </Link>
                      <Link
                        to='/#sobre-a-sapientia'
                        css={linkTo}
                      >
                        SOBRE
                      </Link>
                      <Link
                        to='/#quem-participa'
                        css={linkTo}
                      >
                        BENEFÍCIOS
                      </Link>
                      <Link
                        to='/#cronograma'
                        css={linkTo}
                      >
                        RIEMMA
                      </Link>
                      <Link
                        to='/edicoes-anteriores'
                        css={linkTo}
                      >
                        DICAS
                      </Link>
                      <Link
                        to='/depoimentos'
                        css={linkTo}
                      >
                        DEPOIMENTOS
                      </Link>
                      <div style={{ marginRight: 60 }} />
                    </Fragment>
                  ):
                  (
                    <Fragment>
                       <div css={desktop}><Modal /></div>
                      <div css={mobile} style={{ flexDirection: 'column' }}>
                        <div css={headerMobile}>
                          <a
                            href='/'
                            css={mobile}
                            style={{ border: 'none' }}
                          >
                            <img src={LogoWhite} alt='Logo' style={{ maxWidth: '80%', marginRight: 60 }} />
                          </a>
                          <div>
                            <a 
                              href=''
                              style={{ border: 'none' }}
                            >
                              <img src={Close} />
                            </a>
                          </div>
                        </div>
                        <Accordion>
                          <AccordionItem uuid='1'>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                A Kingdom
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div css={linksAcordionMobile}>
                                <Link to='/nossa-historia'>Nossa História</Link>
                                <Link to='/estrutura'>Nossa Estrutura</Link>
                                <Link to='/nossa-metodologia'>Nossa Metodologia</Link>
                                <Link to='/nossos-pilares'>Nossos Pilares</Link>
                                <Link to='/nossos-diferenciais'>Nossos Diferenciais</Link>
                                <Link to='/missao-visao-valores'>Missão, Visão e Valores</Link>
                                <Link to='/mensagem-da-direcao'>Mensagem da Direção</Link>
                              </div>				
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Nossas Unidades
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div css={linksAcordionMobile}>
                                <Link to='/kingdom-kids'>Kingdom Kids</Link>
                                <Link to='/kingdom-school'>Kingdom School</Link>
                              </div>				
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Ciclo Escolar
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div css={linksAcordionMobile}>
                                <Link to='/educacao-infantil'>Educação Infantil</Link>
                                <Link to='/ensino-fundametal'>Ensino Fundamental</Link>
                                      
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                        <Link to='/atividades-e-projetos' style={{ paddingLeft: 24 }}>Atividades e Projetos</Link>
                        <Link to='/calendario' style={{ paddingLeft: 24 }}>Calendário Escolar</Link>
                        <Link to='/blog' style={{ paddingLeft: 24 }}>Blog</Link>
                        <Link to='/contatos' style={{ paddingLeft: 24 }}>Contato</Link>
                        <Link to='/' style={{ paddingLeft: 24 }}>Serviço aos Pais</Link>
                        
                        <Link to='/' style={{ paddingLeft: 24 }}>Matrículas Abertas</Link>
                        <div css={socialIcons} style={{ margin: 24, display: 'flex' }}>
                          <a style={{ border: 'none' }} href='https://www.facebook.com/kingdomschooldf'><img src={Facebook} /></a>
                          <a style={{ border: 'none' }} href='https://www.instagram.com/kingdomschooldf/'><img src={Instagram} /></a>
                        </div>
                      </div>
                    </Fragment>
                  )
                }
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default NavBar
